import React from "react";
import { GoogleBrandIcon } from "@/components/svg/library-icons";
import { GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo } from "firebase/auth";
import { auth, provider } from "@/firebase/firebaseConfig";
import { updateUserProfile, UserProfile } from "@/lib/UserProfile";
import { getBrandingTemplateByUid } from "@/lib/BrandingTemplate";
import { useRouter } from "next/navigation";
export function GoogleAuthButton({
  text
}: {
  text: string;
}) {
  const router = useRouter();
  const handleGoogleAuth = async () => {
    try {
      console.log('Google Auth button clicked');
      const userCredential = await signInWithPopup(auth, provider);
      console.log('User credential', userCredential);

      // This gives you a Google Access Token. You can use it to access Google APIs
      const credential = GoogleAuthProvider.credentialFromResult(userCredential);
      // const token = credential?.accessToken;
      console.log('Google Credential', credential);

      // The signed-in user info
      const user = userCredential.user;
      const additionalUserInfo = getAdditionalUserInfo(userCredential);
      const isNewUser = additionalUserInfo?.isNewUser || false;

      // Redirect the user to the appropriate page
      if (user) {
        console.log('User from Google', user, "isNewUser", isNewUser);
        getBrandingTemplateByUid(user?.uid).then(brandingTemplate => {
          if (brandingTemplate) {
            router.push('/video');
          } else {
            router.push('/setup-branding');
          }
        });
      } else {
        console.warn('No user info yet.');
      }
      try {
        console.log('Updating user profile', user);
        // Update user profile with data from Google
        await updateUserProfile({
          uid: user.uid,
          displayName: user.displayName || undefined,
          email: user.email || undefined,
          photoURL: user?.photoURL || undefined,
          onboardEditScreen: false,
          onboardPricing: false
        } as UserProfile);
      } catch (error) {
        console.error('Error updating user profile', error);
      }
    } catch (error: any) {
      // Handle Errors here
      console.log('Google Auth error handler', error);
      const errorCode = error?.code;
      const errorMessage = error?.message;
      const email = error.customData?.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error("Error during sign-in: ", errorCode, errorMessage, email, credential);
    }
  };
  return <div className='d-flex justify-content-center align-items-center google-border pointer-event' role='button' onClick={handleGoogleAuth} data-sentry-component="GoogleAuthButton" data-sentry-source-file="GoogleAuthButton.tsx">
            <GoogleBrandIcon data-sentry-element="GoogleBrandIcon" data-sentry-source-file="GoogleAuthButton.tsx" />
            <p>Sign {text} with Google</p>
        </div>;
}