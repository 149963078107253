import { AuthLayout } from "@/layouts/auth-layout";
import React from "react";
import { useAuthContext } from "@/context/AuthContext";
import { useRouter } from "next/navigation";
import { signIn } from "@/firebase/firebaseAuth";
import { Button, Col, Form, Row } from "react-bootstrap";
import { logAnalyticsEvent, useLogAnalyticsEvent } from "@/firebase/firebaseAnalytics";
import { GoogleAuthButton } from "@/components/common/GoogleAuthButton";
import Link from "next/link";
import { getBrandingTemplateByUid } from "@/lib/BrandingTemplate";
import { auth } from "@/firebase/firebaseConfig";
export default function Login() {
  useLogAnalyticsEvent('view-login');
  const {
    user
  } = useAuthContext();
  const router = useRouter();

  //Redirects the page if the user is already logged in
  React.useEffect(() => {
    if (user) {
      getBrandingTemplateByUid(user?.uid).then(brandingTemplate => {
        if (brandingTemplate) {
          router.push('/video');
        } else {
          router.push('/setup-branding');
        }
      });
    } else {
      console.error('No user info yet');
    }
  }, [user]);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleForm = async (event: any) => {
    event.preventDefault();
    const {
      credential,
      error
    } = await signIn(email, password);
    if (error) {
      console.error(error.code, error.message);
      setErrorMessage(error.message);
    } else {
      logAnalyticsEvent('login', {
        method: 'email'
      });
      return router.push("/video");
    }
  };
  return <>
        <AuthLayout title={'Login'} data-sentry-element="AuthLayout" data-sentry-source-file="login.tsx">
            <GoogleAuthButton text={'in'} data-sentry-element="GoogleAuthButton" data-sentry-source-file="login.tsx" />
            <div className="separator my-7 mx-12">Or sign in with email</div>
            <Form onSubmit={handleForm} data-sentry-element="Form" data-sentry-source-file="login.tsx">
                <Row className="g-5" data-sentry-element="Row" data-sentry-source-file="login.tsx">
                    <Form.Group controlId="email" data-sentry-element="unknown" data-sentry-source-file="login.tsx">
                        {/*<Form.Label>Email address</Form.Label>*/}
                        <Form.Control type="email" onChange={e => setEmail(e.target.value)} placeholder="Email Address" required data-sentry-element="unknown" data-sentry-source-file="login.tsx" />
                    </Form.Group>
                    <Form.Group controlId="password" data-sentry-element="unknown" data-sentry-source-file="login.tsx">
                        <div className="d-flex justify-content-between gap-2 mb-2 align-items-center">
                            {/*<Form.Label>Password</Form.Label>*/}
                        </div>
                        <Form.Control type="password" autoComplete="current-password" placeholder="Password" onChange={e => setPassword(e.target.value)} required data-sentry-element="unknown" data-sentry-source-file="login.tsx" />
                        <div className="d-flex justify-content-end mt-2">
                            <Link href="/resetPassword" className="text-sm text-muted text-primary-hover text-underline" data-sentry-element="Link" data-sentry-source-file="login.tsx">
                                Forgot password?
                            </Link>
                        </div>
                    </Form.Group>
                    <Col className={`${errorMessage ? 'collapse show' : 'collapse'}`} data-sentry-element="Col" data-sentry-source-file="login.tsx"><p className="text-danger text-sm">{errorMessage}</p></Col>
                    <Form.Group className={`${!errorMessage ? 'pt-1' : ''}`} data-sentry-element="unknown" data-sentry-source-file="login.tsx">
                        <Button type="submit" className="btn btn-primary w-100 w-lg-auto px-lg-10" data-sentry-element="Button" data-sentry-source-file="login.tsx">
                            Sign in
                        </Button>
                    </Form.Group>
                </Row>
            </Form>
            <p></p>
        </AuthLayout>
    </>;
}